<template>
  <div class="scanner">
    <div class="content-settlement" v-if="isShowSettlement">
      <el-row style="margin-bottom: 16px">
        <el-col :span="12">
          <label-span label="结算单号：" :span="dataId" />
        </el-col>
        <el-col :span="12">
          <label-span label="单据日期：" :span="settleTime" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <label-span label="购货方名称：" :span="buyerName" />
        </el-col>
        <el-col :span="12">
          <label-span label="结算主体：" :span="sellerName" />
        </el-col>
      </el-row>
    </div>
    <div class="scanner-header" v-if="isConnect">
      <div class="header-left">
        <label-span label="本次共识别发票：" :span="totals" />
        <div>
          <label style="color: #09bb07">识别成功：</label>
          <span style="color: #1890ff">{{ success }}</span>
        </div>
        <div>
          <label style="color: #f5222d">识别失败：</label>
          <span style="color: #1890ff" @click="handleFailureInvoice('F')">{{ failure }}</span>
        </div>
        <div>
          <label style="color: #ff7a08">识别重复：</label>
          <span style="color: #1890ff" @click="handleFailureInvoice('R')">{{ repeat }}</span>
        </div>
      </div>
      <el-button type="text" @click="btnStatus = !btnStatus">切换图表</el-button>

      <div class="header-right">
        <el-select v-model="scanner">
          <el-option v-for="item in scanners" :value="item" :key="item"></el-option>
        </el-select>
        <el-button type="primary" :disabled="isScan" @click="handleStartScan">开始扫描</el-button>
      </div>
    </div>
    <div class="scanner-title" v-else>
      <div class="scanner-no-connect">
        <div>
          <img src="@/assets/icon/tips@2x.png" />
          <span>请连接高速扫描仪，并确保多啦客户端在线</span>
          <i class="el-icon-refresh-right" style="cursor: pointer" @click="handleScanners" aria-placeholder="点击刷新扫描设备"></i>
        </div>
      </div>
    </div>
    <div class="scanner-content">
      <div class="content-top">
        <div class="content-top-title">
          <label-span label="识别批次：" :span="batchNo" />
          <label-span label="收票组织：" style="margin-left: 20px" />
          <organization-select :model="org" allLabel="请选择收票组织" :is-add-all="false" style="display: flex; height: 20px; align-items: center" />
          <el-switch class="content-top-title-switch" v-model="scanImageType" active-value="1" inactive-value="0" @change="changeColorImage" active-color="#13ce66" inactive-color="#ff4949" active-text="保存彩色文件" />
          <el-tooltip placement="top" :content="tips"><i class="el-icon-warning-outline" style="display: flex; align-items: center; color: red"></i></el-tooltip>
        </div>
        <el-button type="primary" :disabled="isSubmit" @click="handleScannerSubmit">
          {{ buttonContent }}
        </el-button>
      </div>
      <div class="content-body-img" v-if="invoices.length > 0 && btnStatus">
        <invoice-recognize v-for="invoice in showSuccessInvoice" :invoice="invoice" :invoices="showSuccessInvoice" :key="invoice.id" @invoice-delete="handleRecognizeInvoiceDelete"> </invoice-recognize>
      </div>
      <div class="table_content" v-show="invoices.length > 0 && !btnStatus">
        <RecognizeFailureRecord @getCurEditInvoiceIds="getCurEditInvoiceIds" :isShowSearch="false" :from="'scanner_collect_invoice'" :curBatchNo="batchNo" :btnStatus="btnStatus" :unqueriedImageList="unqueriedImageList" />
      </div>
      <div v-if="!invoices.length" class="content-body-nodata">
        <img src="@/assets/icon/nodata@2x.png" />
        <span>无发票数据！</span>
        <span>点击【开始扫描】，驱动扫描仪批量扫描发票</span>
      </div>
    </div>

    <!-- 编辑失败弹窗 -->
    <el-dialog append-to-body width="35%" :visible.sync="failureVisible" :show-close="false" :center="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog-success">
        <span>当前批次识别全部失败，您可在识别失败记录页面重新编辑发票信息</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleContinueUpload">取消</el-button>
        <el-button type="primary" @click="handleFailureInvoice">识别失败记录</el-button>
      </div>
    </el-dialog>
    <!-- 提交成功弹窗 -->
    <el-dialog append-to-body width="30%" :visible.sync="successVisible" :show-close="false" :center="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog-success">
        <span>提交成功</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleContinueUpload">继续上传</el-button>
        <el-button @click="handleFailureInvoice">处理失败发票</el-button>
        <el-button type="primary" @click="handleBackInvoiceCenter">{{ successTitle }}</el-button>
      </div>
    </el-dialog>
    <!-- 强制离开弹窗 -->
    <el-dialog append-to-body title="提示" width="400px" :visible.sync="recognizeLeave">
      <div class="dialog-leave">当前识别的票据信息未提交，强制离开会导致发票信息丢失</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleFailureInvoice" style="text-align: right">强制离开</el-button>
        <el-button type="primary" @click="handleScannerSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getImageInvoice, getScannerList, startScanner, submitInvoice } from '@/service/scanner';
import { commonsMethods } from '@/util/mixins';
import InvoiceRecognize from '@/components/invoice/InvoiceRecognize';
import { deleteImageInvoice, getRecognizeBatchNo, unqueriedImage } from '@/service/invoice-center';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import RecognizeFailureRecord from './RecognizeFailureRecord';
export default {
  name: 'ColScsp',
  components: { InvoiceRecognize, OrganizationSelect, RecognizeFailureRecord },
  mixins: [commonsMethods],
  data() {
    return {
      isConnect: false,
      scanners: [],
      scanner: '',
      isScan: false,
      invoices: [],
      timer: '',
      isSettlement: false,
      oriTradeId: '',
      dataId: '',
      failureVisible: false,
      successVisible: false,
      successTitle: '返回收票中心',
      isBlend: false,
      source: 'CENTER',
      channel: 'SCANNER',
      invoiceLabel: 'ordinary',
      settleTime: '',
      buyerName: '',
      sellerName: '',
      batchNo: '--', //202303071602531776
      isSubmit: true,
      recognizeLeave: false,
      recognizeLeaveType: '',
      scanImageType: '0',
      tips: '开启保存彩色文件会导致内存过大，系统使用缓慢（扫描仪不支持彩色文件时系统无法保存彩色文件）',
      org: {
        orgId: undefined
      },
      btnStatus: true, //true：图文模式 false：列表模式
      unqueriedImageList: [], //识别结果
      curEditIds: [] //在列表里编辑过的内容id
    };
  },
  watch: {
    scanner(value) {
      if (this.invoices.length > 0) return;
      return value == '' ? '' : (this.isScan = false);
    }
  },
  computed: {
    totals() {
      return this.invoices.length + '份';
    },
    success() {
      return this.invoices.filter((item) => item.status == 'SUCCESS').length + '份';
    },
    failure() {
      return this.invoices.filter((item) => item.status == 'FAILURE' || item.status == 'UNMATCHED').length + '份';
    },
    repeat() {
      return this.invoices.filter((item) => item.status == 'REPEAT').length + '份';
    },
    showSuccessInvoice() {
      return this.invoices.filter((item) => item.status == 'SUCCESS');
    },
    isShowSettlement() {
      return this.channel == 'SETTLEMENT_SUPPLIER';
    },
    buttonContent() {
      return this.isSettlement ? '上传发票' : '提交';
    }
  },
  created() {
    this.handleScanners();
    let source = this.$route.query.source;
    this.org.orgId = this.$store.state.user.currUser.orgId;
    if (source == 'settlement') {
      this.isSettlement = true;
      this.oriTradeId = this.$route.query.id;
      this.dataId = this.$route.query.dataId;
      this.isBlend = JSON.parse(this.$route.query.isBlend);
      this.source = 'SETTLEMENT';
      const channel = this.$route.query.channel;
      if (channel == 'SETTLEMENT_SUPPLIER') {
        this.channel = 'SUPPLIER';
      } else {
        this.channel = 'RETAILER';
      }
      if (this.isShowSettlement) {
        this.settleTime = this.$route.query.settleTime;
        this.buyerName = this.$route.query.buyerName;
        this.sellerName = this.$route.query.sellerName;
      }
    }
  },
  methods: {
    // 切换图表后在列表里编辑过的内容
    getCurEditInvoiceIds(ids) {
      ids = ids.filter((v) => v != null);
      if (ids.length) {
        this.curEditIds = ids;
      }
    },
    _unqueriedImage() {
      unqueriedImage({ batchNo: this.batchNo, unIds: null }).then((res) => {
        const { success, data } = res;
        if (success) {
          // let map = new Map();
          // for (let i of data) {
          //   if (!map.has(i.invoiceCode) && !map.has(i.invoiceNo)) {
          //     map.set(i.invoiceCode, i);
          //   }
          // }
          // this.unqueriedImageList = [...map.values()];
          this.unqueriedImageList = data;
        }
      });
    },
    /* 扫描仪列表 */
    async handleScanners() {
      const loading = this.handleLoading('扫描仪列表获取中，请稍后...');
      // 扫描列表请求
      const scannerListResult = await getScannerList();
      if (!scannerListResult.success) {
        this.toast(scannerListResult.message, 'error');
        loading.close();
        return;
      }
      // 扫描列表结果处理
      this.isConnect = true;
      this.scanners = scannerListResult.data;
      loading.close();
    },
    /* 启动扫描仪 */
    async handleStartScan() {
      if (!this.org.orgId) {
        this.toast('请选择收票组织！', 'warning');
        return;
      }

      const loading = this.handleLoading('正在扫描，请稍后...');
      // 启动扫描仪
      const startScannerResult = await startScanner({ scanner: this.scanner, scanImageType: this.scanImageType });
      if (!startScannerResult.success) {
        this.toast(startScannerResult.message, 'error');
        loading.close();
        return;
      }
      this.batchNo = startScannerResult.data;

      // 扫描结果处理
      let flag = true;
      this.timer = window.setInterval(() => {
        setTimeout(() => {
          getImageInvoice({ batchNo: this.batchNo, ids: this.invoices.map((item) => item.id) }).then((res) => {
            if (res.success) {
              // 票据列表
              res.data.imageInvoices.forEach((invoice) => {
                if (invoice.invoiceType == 'VatInvoiceList') {
                  // 同批次识别校验
                  let repeatIndex = this.invoices.findIndex((item) => item.invoiceCode == invoice.invoiceCode && item.invoiceNo == invoice.invoiceNo && item.invoiceType == 'VatInvoiceList' && item.pageIndex == invoice.pageIndex);
                  if (repeatIndex != -1) invoice.status = 'REPEAT';

                  // 发票数据校验
                  if (invoice.pageSize == '' || invoice.pageIndex == '') invoice.status = 'FAILURE';

                  // 同批次内匹配发票
                  this.invoices.forEach((item) => {
                    if (item.invoiceCode == invoice.invoiceCode && item.invoiceNo == invoice.invoiceNo && item.invoiceType != 'VatInvoiceList' && invoice.status == 'UNMATCHED') {
                      invoice.status = 'SUCCESS';
                    }
                  });
                } else {
                  // 同批次识别校验
                  let repeatIndex = this.invoices.findIndex((item) => item.invoiceCode == invoice.invoiceCode && item.invoiceNo == invoice.invoiceNo && item.invoiceType != 'VatInvoiceList');
                  if (repeatIndex != -1) invoice.status = 'REPEAT';

                  // 发票数据校验
                  if (invoice.invoiceCode == '' || invoice.invoiceNo == '') invoice.status = 'FAILURE';

                  // 同批次内匹配发票
                  let invoiceLists = this.invoices.filter((item) => item.invoiceCode == invoice.invoiceCode && item.invoiceNo == invoice.invoiceNo && item.invoiceType == 'VatInvoiceList' && item.status == 'UNMATCHED');
                  if (invoiceLists.length != 0) {
                    invoiceLists.forEach((item) => (item.status = 'REPEAT'));

                    const hash = {};
                    let templateList = invoiceLists.reduceRight((item, next) => {
                      if (!hash[next.pageIndex]) hash[next.pageIndex] = true && item.push(next);
                      return item;
                    }, []);
                    templateList.forEach((item) => (item.status = 'SUCCESS'));
                  }
                }

                // 发票是否已存在
                let idIndex = this.invoices.findIndex((item) => item.id == invoice.id);
                if (idIndex == -1) this.invoices.push(invoice);
              });

              // 是否启动
              if (this.invoices.length > 0) {
                this.isScan = true;
                loading.close();

                if (flag) {
                  this.$message({
                    duration: 0,
                    message: '正在扫描，请勿离开页面！',
                    type: 'warning'
                  });
                  flag = false;
                }
              }

              // 扫描结束
              if (res.data.finished) {
                loading.close();
                const successLength = this.invoices.filter((item) => item.status == 'SUCCESS').length;
                if (successLength == 0) {
                  this.failureVisible = true;
                } else {
                  this.isSubmit = false;
                }
                this.toast('扫描已结束！', 'success');
                clearInterval(this.timer);
                this._unqueriedImage();
              }
            }
          });
        }, 0);
      }, 3000);
    },
    /* 识别发票删除 */
    handleRecognizeInvoiceDelete(id) {
      deleteImageInvoice(id).then((res) => {
        if (res.success) {
          this.invoices.forEach((item, index) => {
            if (item.id == id) {
              this.invoices.splice(index, 1);
              if (this.invoices.length == 0) {
                this.handleContinueUpload();
              }
            }
          });
          this.$message.closeAll();
          if (this.invoices.length > 0) {
            this.toast('删除成功', 'success');
          }
        } else {
          this.toast('删除失败！', 'error');
        }
      });
    },
    /* 扫描收票提交 */
    async handleScannerSubmit() {
      const loading = this.handleLoading('扫描发票正在提交，请稍后...');
      const tmpIds = [...new Set([...this.invoices.filter((item) => item.status == 'SUCCESS').map((item) => item.id), ...this.curEditIds])];
      let param = {
        ids: tmpIds,
        batchNo: this.batchNo,
        source: this.source,
        channel: this.channel,
        invoiceLabel: this.invoiceLabel,
        oriTradeId: this.oriTradeId,
        isBlend: this.isBlend,
        orgId: this.org.orgId
      };
      // 发票提交请求
      const { success } = await submitInvoice(param);
      if (!success) {
        this.toast('发票提交异常！', 'error');
        loading.close();
        return;
      }
      // 发票提交成功处理
      this.successVisible = true;
      if (this.isSettlement) {
        if (this.channel == 'SUPPLIER') {
          this.successTitle = '返回结算开票';
        } else {
          if (this.isBlend) {
            this.successTitle = '返回匹配列表';
          } else {
            this.successTitle = '返回上传列表';
          }
        }
      }
      loading.close();
    },
    /* 继续上传 */
    async handleContinueUpload() {
      this.invoices = [];
      this.isSubmit = true;
      this.isScan = false;
      this.successVisible = false;
      this.failureVisible = false;

      // 批次编号获取
      const { success, data } = await getRecognizeBatchNo();
      if (success) {
        this.batchNo = data;
      } else {
        this.toast('识别批次编号获取失败！', 'error');
      }
    },
    /* 处理失败发票 */
    handleFailureInvoice(value) {
      if (value == 'F' || value == 'R') {
        this.recognizeLeave = true;
        this.recognizeLeaveType = value;
      } else {
        let status = '';
        if (this.recognizeLeaveType != '') {
          status = this.recognizeLeaveType == 'F' ? 'FAILURE' : 'REPEAT';
        }
        // 跳转页面
        this.$router.replace({
          path: '/collect/recognize/failure/record',
          query: { batchNo: this.batchNo, status: status }
        });
      }
    },
    /* 返回收票中心 */
    handleBackInvoiceCenter(param, ofdResult = false) {
      switch (param) {
        case 'back':
          if (this.channel == 'SUPPLIER') {
            // 结算开票
            this.$router.replace('/supplier/settlement');
            if (!ofdResult) {
              this.toast('已上传发票，请在票单匹配表中查看稽查结果', 'success');
            }
          } else if (this.channel == 'SETTLEMENT_RETAILER') {
            if (this.isBlend) {
              // 结算收票
              this.$router.replace({
                path: '/retailer/settlement/invoice-match',
                query: { batchNo: window.btoa(this.batchNo), type: 'retailer' }
              });
            } else {
              this.isSettlement = false;
              this.$router.replace({
                path: '/retailer/settlement/uploadInvoiceList',
                query: { id: window.btoa(this.oriTradeId), type: 'retailer' }
              });
            }
          } else {
            // 收票中心
            this.$router.replace('/collect/list');
          }
          break;
        default: // 返回收票中心
          this.$router.replace('/collect/list');
          break;
      }
    },
    changeColorImage() {
      if (this.scanImageType == '1') {
        this.toast('请连接扫描仪后再开启此按钮！', 'warning');
      }
    }
  },
  beforeDestroy() {
    this.$message.closeAll();
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.scanner {
  height: 100%;
  width: 100%;
  font-size: 14px;

  .content-settlement {
    padding: 24px;
    font-size: 16px;
    line-height: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;

    label {
      font-weight: 400;
      color: #666666;
    }

    span {
      font-weight: 500;
      color: #333333;
    }
  }

  .scanner-header {
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 24px;

    .header-left {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-right {
      display: flex;

      .el-button {
        margin-left: 8px;
      }
    }
  }

  .scanner-title {
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    display: flex;
    justify-content: center;
    padding: 24px;

    .scanner-no-connect {
      width: 95%;
      display: flex;
      justify-content: center;

      div {
        width: 604px;
        height: 40px;
        background: rgba(230, 247, 255, 1);
        border-radius: 2px;
        border: 1px solid rgba(145, 213, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }

        span {
          width: 520px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
      }
    }
  }

  .scanner-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow-x: auto;
    margin: 24px auto;
    height: calc(100% - 86px);

    .content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding: 16px 24px;
      margin-bottom: 24px;

      .content-top-title {
        display: flex;
        justify-content: space-between;

        .content-top-title-switch {
          margin-left: 20px;
          margin-right: 10px;
        }

        .success {
          width: 30px;
          height: 16px;
          border: 1px solid #09bb07;
          margin-right: 12px;
        }

        .fail {
          width: 30px;
          height: 16px;
          border: 1px solid #f5222d;
          margin: 0px 12px 0px 32px;
        }
      }
    }

    .content-body-nodata {
      height: 660px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 104px;
        height: 98px;
        margin-top: 80px;
        margin-bottom: 9px;
      }

      :nth-child(2) {
        width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 25px;
        margin-bottom: 20px;
      }

      :last-child {
        width: 280px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
    }

    .content-body-img {
      display: grid;
      margin: 0px 50px;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.dialog-success {
  margin: 24px;
}

.dialog-back {
  margin: 24px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
}

.dialog-success {
  margin: 24px;
}

.dialog-leave {
  margin: 24px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  text-align: center;
  padding: 0 24px 24px;
}
</style>
