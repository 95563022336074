import { get, post } from './index.js'


/**
 * 扫描仪连接
 * @param {*} data
 */
export const queryScanId = (data) => {
  return post('/scan/queryScanId', data).then(res => res)
}
/**
 * 获取扫描仪列表
 * @param {*} data
 */
export const getScannerList = () => {
  return get('/scanner/getScannerList', {}).then(res => res)
}
/**
 * 启动扫描仪
 * @param {*} data
 */
export const startScanner = (data) => {
  return get('/scanner/startScanner', data).then(res => res)
}
/**
 * 扫描仪发票数据
 * @param {*} data
 */
export const getImageInvoice = (data) => {
  return get('/scanner/invoice/' + data.batchNo + '/' + data.ids).then(res => res)
}
/**
 * 发票提交
 * @param {*} data
 */
export const submit = (data) => {
  return post('/scanner/submit', data).then(res => res)
}

/**
 * 发票提交（新）
 * @param {*} data
 */
export const submitInvoice = (data) => {
  return post('/image/invoice/submit', data).then(res => res)
}

